import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { COUNTRIES, COUNTRY_CODE_FINLAND, COUNTRY_CODE_SWEDEN, COUNTRY_CODE_UNITED_KINGDOM, COUNTRY_CODE_GERMANY, COUNTRY_GLOBAL, DEFAULT_COUNTRY } from '~/utils/constants'

Vue.use(VueI18n)

export default function ({ route, app }, inject) {
  const paramCountry = route.params.country
  const pathCountry = route.path.split('/')[1] // for pages defined via files. For eq. /fi-fi/asiakirjamallit

  const currentCountry = COUNTRIES[paramCountry || pathCountry] || COUNTRIES[DEFAULT_COUNTRY]

  const country = {
    FINLAND: COUNTRY_CODE_FINLAND,
    SWEDEN: COUNTRY_CODE_SWEDEN,
    UK: COUNTRY_CODE_UNITED_KINGDOM,
    GERMANY: COUNTRY_CODE_GERMANY,
    GLOBAL: COUNTRY_GLOBAL,
    all: Object.keys(COUNTRIES).map(c => COUNTRIES[c]),
    current: currentCountry,
    setCurrent (current) {
      this.current = current
      app.$i18n.locale = current.code

      // Set cookie
      // The middleware.js file also sets a cookie, but it doesn't properly combine cookies
      // set by the Nuxt server. This is only a problem in the local environment because we
      // run a static site in production, and it doesn't set cookies on the server side.
      if (process.client) {
        app.$cookies.set('docue_country', current.code, {
          maxAge: 60 * 60 * 24 * 365,
          secure: app.$config.cookie.secure,
          path: '/',
          domain: app.$config.cookie.domain
        })
      }
    },
    getCookieCountry () {
      return COUNTRIES[app.$cookies.get('docue_country')]
    },
    path (path, countryCode) {
      if (path === '/') {
        path = ''
      }

      return `/${(countryCode || this.current.code)}${path}`
    },
    is (code) {
      return this.current?.code === code
    },
    isFinland () {
      return this.current?.code === COUNTRY_CODE_FINLAND
    },
    isSweden () {
      return this.current?.code === COUNTRY_CODE_SWEDEN
    },
    isUnitedKingdom () {
      return this.current?.code === COUNTRY_CODE_UNITED_KINGDOM
    },
    isGermany () {
      return this.current?.code === COUNTRY_CODE_GERMANY
    },
    isGlobal () {
      return this.current?.code === COUNTRY_GLOBAL
    },
    hasTemplates () {
      return ![COUNTRY_GLOBAL].includes(this.current?.code)
    },
    hasApp () {
      return ![COUNTRY_GLOBAL].includes(this.current?.code)
    }
  }

  inject('country', country)

  // Initialize i18n
  const i18n = new VueI18n({
    locale: currentCountry.code,
    fallbackLocale: DEFAULT_COUNTRY,
    messages: {
      'fi-fi': require('~/lang/fi_FI.json'),
      'sv-se': require('~/lang/sv_SE.json'),
      'en-gb': require('~/lang/en_GB.json'),
      'de-de': require('~/lang/de_DE.json'),
      en: require('~/lang/en.json'),
    },
    dateTimeFormats: {
      'fi-fi': {
        date: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        short: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        }
      },
      'sv-se': {
        date: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        short: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      },
      'en-gb': {
        date: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        short: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      },
      'de-de': {
        date: {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        },
        short: {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }
      },
    },
    numberFormats: {
      'fi-fi': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
      'sv-se': {
        currency: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'SEK',
          minimumFractionDigits: 2
        }
      },
      'en-gb': {
        currency: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'GBP',
          minimumFractionDigits: 2
        }
      },
      'de-de': {
        currency: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0
        },
        currencyLong: {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 2
        }
      },
    }
  })

  app.i18n = i18n
  inject('i18n', i18n)

  // Set initial country because middlewares
  // are not run on client side in first page load
  if (process.client) {
    country.setCurrent(currentCountry)
  }
}
